import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import SEO from '../components/seo';
import * as Widget from '../components/Widget';
import LoginForm from '../forms/LoginForm';

function LandingPage(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');

  async function onLogin({username, password}) {
    actions.setLoading(true);
    try {
      await actions.login({username, password});
      navigate('/summary');
      // navigate('/article');
    } catch (ex) {
      console.warn(ex);
      alert('Login fail, please check username and password');
    }
    actions.setLoading(false);
  }

  return (
    <>
      <SEO title="Revteltech Dashbhard" />

      <Wrapper>
        <Widget.FlexColCenter style={{position: 'relative', height: '100%'}}>
          {!user && <LoginForm onLogin={onLogin} />}
          {user && (
            <div>
              <h2>Welcome, {user.username}!</h2>
            </div>
          )}
        </Widget.FlexColCenter>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  background-color: #eee;
`;

export default LandingPage;
