import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as Widget from '../components/Widget';

function LoginForm(props) {
  const {onLogin, defaultUsername = ''} = props;
  const [username, setUsername] = React.useState(''); // //inigma-admin
  const [password, setPassword] = React.useState(''); // //Inigma123

  return (
    <>
      <Wrapper>
        <Ant.Form
          onFinish={() => {
            onLogin({username, password});
          }}>
          <label>Username</label>
          <Ant.Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <Widget.PaddingHeight size={15} />

          <label>Password</label>
          <Ant.Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Widget.FlexRow style={{marginTop: 60, justifyContent: 'flex-end'}}>
            <Ant.Button type="primary" htmlType="submit">
              LOGIN
            </Ant.Button>
          </Widget.FlexRow>
        </Ant.Form>
      </Wrapper>

      <Widget.PaddingHeight size={80} />
    </>
  );
}

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 400px;

  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

export default LoginForm;
